<template>
	<OrganismsFooter v-if="!isApp" :copyright="copyright" :privacyManagerId="privacyManagerId" :footernav="footerNav"/>
</template>

<script lang="ts" setup>
	const isApp = useIsApp();

	const config = useClientSideConfig();

	const copyright = {
		url: config?.footerCopyright?.url,
		external: true,
		label: config?.footerCopyright?.label?.replace(/\{\{\s*year\s*\}\}/g, new Date().getFullYear()),
	};

	const footerNavID = config?.footerMenuId;
	let footerNav = ref({});
	if (footerNavID) {
		const footerNavResponse = await useFetch("/api/menu/" + footerNavID);
		footerNav.value = footerNavResponse?.data?.value as any[] || [];
	}

	const privacyManagerId = ref(config?.cmp_privacyManagerId);
</script>
