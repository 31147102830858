export default (sourcePublication: string, menu: string) => {
	const config = useNuxtApp().vueApp.$clientSideConfig;
	const publicationMenus: any = {
		swp: {
			service: {
				items: [
					{
						label: config?.meinBereichHeadline,
						url: "/meine-swp/#empfehlungen",
						title: config?.meinBereichHeadline,
						icon: "user",
					},
					{
						label: "Merkliste",
						url: "/meine-swp/#merkliste",
						title: "Merkliste",
						icon: "bookmark",
					},
					{
						label: "Newsletter",
						url: "/newsletter/",
						title: "Newsletter",
						icon: "newsletter",
					},
					{
						label: "Push",
						url: "/",
						title: "Push",
						icon: "bell",
					},
					{
						label: "E-Paper",
						url: "https://epaper.swp.de",
						title: "E-Paper",
						icon: "epaper",
						external: true,
					},
				],
			},
			more: {
				title: "Mehr von swp.de",
				items: [
					{
						label: "Aboshop",
						url: "https://abo.swp.de/",
						title: "Aboshop",
						external: true,
					},
					{
						label: "Immobilie finden",
						url: "https://immo.swp.de/ulm",
						title: "Immobilie finden",
						external: true,
					},
					{
						label: "Job finden",
						url: "https://bw.bluum.de/",
						title: "Job finden",
						external: true,
					},
					{
						label: "Trauer Portal",
						url: "https://www.trauer.swp.de/",
						title: "Trauer Portal",
						external: true,
					},
					{
						label: "Auto kaufen",
						url: "https://auto.swp.de/ulm/",
						title: "Auto kaufen",
						external: true,
					},
					{
						label: "Wochenblätter lesen",
						url: "https://swp-wochenblatt.weekli.de",
						title: "Wochenblätter lesen",
						external: true,
					},
				],
			},
			support: {
				title: "Support",
				items: [
					{
						label: "Feedback",
						url: "mailto:digitalsupport-SWP@swp.de",
						title: "Feedback",
						icon: "feedback",
						external: true,
					},
					{
						label: "Hilfe",
						url: "https://abo.swp.de/suedwestpresse/digitale-zeitung-faq-themenbereiche",
						title: "Hilfe",
						icon: "help",
						external: true,
					},
				],
			},
			legal: {
				title: "Rechtliches",
				items: [
					{
						label: "AGBs",
						url: "/agb/",
						title: "AGBs",
					},
					{
						label: "Impressum",
						url: "/impressum/",
						title: "Impressum",
					},
					{
						label: "Datenschutz",
						url: "/privacy/",
						title: "Datenschutz",
					},
				],
			},
		},
		lr: {
			service: {
				items: [
					{
						label: config?.meinBereichHeadline,
						url: "/meine-lr/#empfehlungen",
						title: config?.meinBereichHeadline,
						icon: "user",
					},
					{
						label: "Merkliste",
						url: "/meine-lr/#merkliste",
						title: "Merkliste",
						icon: "bookmark",
					},
					{
						label: "Newsletter",
						url: "/newsletter/",
						title: "Newsletter",
						icon: "newsletter",
					},
					{
						label: "Push",
						url: "/",
						title: "Push",
						icon: "bell",
					},
					{
						label: "E-Paper",
						url: "https://epaper.lr-online.de",
						title: "E-Paper",
						icon: "epaper",
						external: true,
					},
				],
			},
			more: {
				title: "Mehr von LR.de",
				items: [
					{
						label: "Aboshop",
						url: "https://abo.lr-online.de/",
						title: "Aboshop",
						external: true,
					},
					{
						label: "Job finden",
						url: "https://bb.bluum.de/",
						title: "Job finden",
						external: true,
					},
					{
						label: "Trauer Portal",
						url: "https://lr-online.trauer.de",
						title: "Trauer Portal",
						external: true,
					},
					{
						label: "Wochenblätter lesen",
						url: "https://epaper-lausitzer-woche.weekli.de",
						title: "Wochenblätter lesen",
						external: true,
					},
				],
			},
			support: {
				title: "Support",
				items: [
					{
						label: "Feedback",
						url: "mailto:digitalsupport-LR@lr-online.de",
						title: "Feedback",
						icon: "feedback",
						external: true,
					},
					{
						label: "Hilfe",
						url: "https://abo.lr-online.de/lausitzer_rundschau/faq",
						title: "Hilfe",
						icon: "help",
						external: true,
					},
				],
			},
			legal: {
				title: "Rechtliches",
				items: [
					{
						label: "AGBs",
						url: "https://lr-medienhaus.de/agb",
						title: "AGBs",
						external: true,
					},
					{
						label: "Impressum",
						url: "https://lr-medienhaus.de/impressum",
						title: "Impressum",
						external: true,
					},
					{
						label: "Datenschutz",
						url: "/privacy/",
						title: "Datenschutz",
					},
				],
			},
		},
		moz: {
			service: {
				items: [
					{
						label: config?.meinBereichHeadline,
						url: "/mein-moz/#empfehlungen",
						title: config?.meinBereichHeadline,
						icon: "user",
					},
					{
						label: "Merkliste",
						url: "/mein-moz/#merkliste",
						title: "Merkliste",
						icon: "bookmark",
					},
					{
						label: "Newsletter",
						url: "/newsletter/",
						title: "Newsletter",
						icon: "newsletter",
					},
					{
						label: "Push",
						url: "/",
						title: "Push",
						icon: "bell",
					},
					{
						label: "E-Paper",
						url: "https://epaper.moz.de",
						title: "E-Paper",
						icon: "epaper",
						external: true,
					},
				],
			},
			more: {
				title: `Mehr von ${config?.jsonLdPageName}`,
				items: [
					{
						label: "Aboshop",
						url: "https://abo.moz.de/",
						title: "Aboshop",
						external: true,
					},
					{
						label: "Job finden",
						url: "https://bb.bluum.de/",
						title: "Job finden",
						external: true,
					},
					{
						label: "Trauer Portal",
						url: "https://trauer.moz.de/",
						title: "Trauer Portal",
						external: true,
					},
				],
			},
			support: {
				title: "Support",
				items: [
					{
						label: "Feedback",
						url: "mailto:digitalsupport-MOZ@moz.de",
						title: "Feedback",
						icon: "feedback",
						external: true,
					},
					{
						label: "Hilfe",
						url: "https://abo.moz.de/maerkische_oderzeitung/faqmozplus",
						title: "Hilfe",
						icon: "help",
						external: true,
					},
				],
			},
			legal: {
				title: "Rechtliches",
				items: [
					{
						label: "AGBs",
						url: "/agb/",
						title: "AGBs",
					},
					{
						label: "Impressum",
						url: "/impressum/",
						title: "Impressum",
					},
					{
						label: "Datenschutz",
						url: "/privacy/",
						title: "Datenschutz",
					},
				],
			},
		},
	};

	return publicationMenus[sourcePublication][menu];
};
