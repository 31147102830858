<template>
	<OrganismsHeader
		:sourcePublication="sourcePublication"
		:mainNavigation="mainNavigation"
		:quicknav="quicknav"
		:tags="tags"
		v-if="!isApp"
		:visible="visibleNav"
		@openNav="toggleNav(true)"
		@closeNav="toggleNav(false)"
	>
		<template v-slot:headerline v-if="headerline && !userHasSubscription">
			<div class="u-header__headerline">
				<AtomsBaseLink class="font-set-2" v-bind="headerline" />
			</div>
		</template>
		<template v-slot:loginButton>
			<ClientOnly>
				<AtomsBaseCTA
					class="u-header__user-page-link fw-bold fs-2 d-flex flex-column text-color-black"
					:url="myPublicationUrl"
					v-if="userLoggedIn"
					version="link"
				>
					<span class="u-icon u-icon--user-filled"></span>
					<span class="u-header__user-page-label">{{ myPublicationLabel }}</span>
				</AtomsBaseCTA>
				<AtomsBaseCTA
					v-if="!userLoggedIn"
					class="fw-bold fs-2"
					id="header-login-button"
					type="button"
					version="link"
					@click="() => handleLoginClick('header', 'Login', '#header-login-button')"
				>
					Login
				</AtomsBaseCTA>
			</ClientOnly>

			<LoginAndRegistrationModal
				:open="showLoginAndRegistrationModal"
				:tab="showRegistrationTab ? 'registration' : 'login'"
				@close="showLoginAndRegistrationModal = false"
				:registration="registrationPopup"
			></LoginAndRegistrationModal>
		</template>
		<template v-slot:navigation>
			<ClientOnly>
				<a class="u-nav__link" :href="`${myPublicationUrl}#mein-konto`" v-if="userLoggedIn">
					<span :class="`u-icon u-icon--account`"></span>Mein Konto
				</a>

				<button
					v-if="!userLoggedIn"
					class="u-nav__link"
					id="sidebar-login-button"
					@click="handleLoginClick('sidebar', 'Anmelden', 'sidebar-login-button')"
				>
					<span :class="`u-icon u-icon--login`"></span>Anmelden
				</button>
				<button
					v-if="userLoggedIn"
					class="u-nav__link"
					id="sidebar-logout-button"
					@click="handleLogoutClick('sidebar', 'Abmelden', 'sidebar-logout-button')"
				>
					<span :class="`u-icon u-icon--logout`"></span>Abmelden
				</button>

				<AtomsSeparator class="u-menu__separator ml-4 mr-4" />

				<!--<NuxtLink class="u-nav__link" :to="myPublicationUrl" v-if="userLoggedIn"
					><span :class="`u-icon u-icon--account`"></span>Mein Konto</NuxtLink
				>-->
			</ClientOnly>
		</template>
	</OrganismsHeader>
</template>

<script lang="ts" setup>
	import { ref, computed, onMounted } from "vue";
	import useClientSideConfig from "~/composables/useClientSideConfig";
	import { DwhTracking } from "~/tracking/track.dwh";
	import { usePageStore } from "~/stores/page.store";
	import { PageType } from "@living-online/types";
	import { useUserDataFromCookie } from "#imports";
	import AtomsBaseLink from "../../storybook/src/components/atoms/AtomsBaseLink.vue";

	const user = useUserDataFromCookie();
	const userHasSubscription = user?.subscription || false;

	const sourcePublication = usePublication();

	const showLoginAndRegistrationModal = ref(false);
	const showRegistrationTab = ref(false);

	const isApp = useIsApp();

	const myPublicationUrl = computed(() => {
		const publication = usePublication();
		const prefix = publication === "moz" ? `mein` : `meine`;
		return `/${prefix}-${publication}/`;
	});
	const myPublicationLabel = usePublicationInfo().userPageTitle;

	const config = useClientSideConfig();

	const headerline = {
		url: config.headerline?.url,
		label: config.headerline?.title,
		rel: "nofollow",
	};

	const registrationPopup = {
		registrationNewsletterText: config.registrationNewsletterText,
		registrationNewsletterTextHint: config.registrationNewsletterTextHint,
		agbUrl: config.agbUrl,
		privacyUrl: config.privacyUrl,
	};

	const mainNavigation = ref(
		{} as {
			user: { items: { url: string; title: string; label: string; icon: string }[] };
			navigation: any[];
			service: { items: { url: string; title: string; label: string; icon: string; external?: boolean }[] };
			more: { items: { url: string; title: string; label: string; external?: boolean }[] };
			support: { items: { url: string; title: string; label: string; external?: boolean }[] };
			legal: { items: { url: string; title: string; label: string; external?: boolean }[] };
		},
	);

	const visibleNav = ref(false);
	const userLoggedIn = ref(false);

	const pageStore = usePageStore();
	const tags = computed(() => {
		const _tags = pageStore.getRetrescoTopics?.map((tag) => {
			return {
				label: tag.name,
				...tag,
			};
		});
		if (_tags && _tags.length !== 0) {
			const cropedTags = _tags?.slice(0, 6);
			cropedTags.push({ name: "Alle Themen", label: "Alle Themen", url: "/thema/" });
			return cropedTags;
		}
		return tags;
	});

	const isArticle = pageStore.getType === PageType.ARTICLE;
	const quicknav = ref({});
	const mainMenuId = config.mainMenuId;
	const mainMenuResponse = await useFetch("/api/menu/" + mainMenuId);
	mainNavigation.value.navigation = (mainMenuResponse?.data?.value as any[]) || [];
	const currentRoute = useRoute().path;

	if (!isArticle) {
		const quickNavMenuId = config.quickNavMenuId;
		const quickNavMenuResponse = await useFetch("/api/menu/" + quickNavMenuId);

		quicknav.value =
			(quickNavMenuResponse?.data?.value as any[])?.map((item) => {
				item.isActive = item.url === currentRoute;
				return item;
			}) || [];
	}

	function openAuthPopup(activeTab: "login" | "registration" = "login") {
		if (isApp && typeof window !== "undefined" && typeof window.ApplicationEvents !== "undefined") {
			const iappsEvent = new ApplicationEvents({});
			iappsEvent.sendEvent(activeTab === "registration" ? "openRegister" : "openLogin");
		} else {
			showLoginAndRegistrationModal.value = true;
			showRegistrationTab.value = activeTab === "registration";
		}
		toggleNav(false);
	}

	async function logout() {
		const { data } = await useFetch("/api/user/logout", { method: "POST" });
		const tracker = new DwhTracking(usePublication());
		tracker.userLoggedOut();

		if (data?.value?.ok === true) {
			location.reload();
		}
	}

	function toggleNav(force?: boolean) {
		if (!document) return;

		if (typeof force === "undefined") {
			force = !visibleNav.value;
		}
		visibleNav.value = force;
	}

	function handleLoginClick(zone: String, label: String, identifier: String) {
		openAuthPopup();
		useClientStore().rememberTopicForLogin = null;
		document.body.dispatchEvent(
			new CustomEvent("avo-element-engaged", {
				detail: {
					action: "clicked",
					label: label,
					elementCategory: "login",
					elementType: "button",
					zone: zone,
					identifier: identifier,
				},
			}),
		);
	}

	function handleLogoutClick(zone: String, label: String, identifier: String) {
		logout();
		document.body.dispatchEvent(
			new CustomEvent("avo-element-engaged", {
				detail: {
					action: "clicked",
					label: label,
					elementCategory: "logout",
					elementType: "button",
					zone: zone,
					identifier: identifier,
				},
			}),
		);
	}

	onMounted(() => {
		document.body.addEventListener("open-login", () => {
			openAuthPopup("login");
		});
		document.body.addEventListener("open-registration", () => {
			openAuthPopup("registration");
		});
		document.body.addEventListener("close-nav", () => {
			toggleNav(false);
		});
		userLoggedIn.value = useUserIsLoggedIn();
	});

	mainNavigation.value.service = useMenuStaticPublications(sourcePublication, "service");
	mainNavigation.value.more = useMenuStaticPublications(sourcePublication, "more");
	mainNavigation.value.support = useMenuStaticPublications(sourcePublication, "support");
	mainNavigation.value.legal = useMenuStaticPublications(sourcePublication, "legal");
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	#header-login-button {
		padding: 0;
	}

	.u-base-cta.u-header__user-page-link {
		gap: 0;
		padding-right: 0;
		white-space: nowrap;

		&:hover {
			color: var(--color-black);
		}
	}

	.u-header__user-page-label {
		display: none;
	}

	@include tablet-above {
		.u-header__user-page-label {
			display: inline;
		}
	}
</style>
