<template>
	<MoleculesModal
		:show="isOpen"
		@close="emit('close', $event)"
		aria-label="Login und Registrierung"
		class="u-login-and-registration-modal"
		:width="'50rem'"
	>
		<OrganismsTabStrip class="u-login-and-registration__tabstrip u-content-width--full" :activeTab="activeTab">
			<MoleculesTab label="Anmeldung" :key="'login'">
				<LoginForm
					:mail="mail"
					v-if="loginAndPasswordResetState === 'login'"
					@goto-passwordreset="loginAndPasswordResetState = 'passwordreset'"
				></LoginForm>
				<PasswordResetInitForm
					v-else-if="loginAndPasswordResetState === 'passwordreset'"
					@goto-login="loginAndPasswordResetState = 'login'"
				></PasswordResetInitForm>
			</MoleculesTab>
			<MoleculesTab label="Registrierung" :key="'registration'">
				<RegistrationForm :input-default-mail="mail" v-bind="registration"></RegistrationForm>
			</MoleculesTab>
		</OrganismsTabStrip>
	</MoleculesModal>
</template>

<script setup lang="ts">
	import { computed, watch, ref } from "vue";
	import type { IRegistrationFormProps } from "./RegistrationForm.vue";

	const props = withDefaults(
		defineProps<{
			open?: boolean;
			mail?: string;
			tab?: "login" | "registration";
			registration?: IRegistrationFormProps;
		}>(),
		{
			open: false,
			tab: "login",
		},
	);
	const emit = defineEmits(["close"]);

	const activeTab = ref("login");
	const loginAndPasswordResetState = ref("login");

	const isOpen = computed(() => {
		return props.open;
	});

	watch(isOpen, () => {
		if (isOpen.value) {
			activeTab.value = props.tab === "registration" ? "registration" : "login";
			loginAndPasswordResetState.value = "login";
		}
	});
</script>

<style lang="scss">
	.u-login-and-registration-modal {
		--padding: 0;
	} /**
     * TABS
     */
	.u-login-and-registration__tabstrip .u-tabs__list {
		--tabs-border-width: 0;
	}

	.u-login-and-registration__tabstrip .u-tabs__tab-button {
		color: var(--color-neutral-50);
		background: var(--color-neutral-05);
		font-style: normal;
		font-weight: 700;
		line-height: 100%;
		height: 4.375rem;
		flex-grow: 1;
		border: 0;

		&:hover,
		&:focus {
			background: var(--color-neutral-05);
		}

		&[aria-selected="true"] {
			background: var(--color-white);
			color: var(--color-black);

			&::after {
				content: "";
				border-radius: 0.3125rem 0.3125rem 0rem 0rem;
				background: var(--swp-de-secondary-100, #05a5c8);
				width: 58.5%;
				height: 0.25rem;
				background-color: var(--color-primary-100);
				display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
</style>
