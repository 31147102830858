<template>
	<ClientOnly>
		<MoleculesBreadcrumbs :crumbs="crumbs" v-if="crumbs.length > 0" />
	</ClientOnly>
</template>

<script lang="ts" setup>
	import type { WithContext, BreadcrumbList } from "schema-dts";
	import { useClientStore } from "~/stores/client.store";
	const clientStore = useClientStore();

	const crumbs = computed(() => {
		return clientStore.breadcrumbs || [];
	});

	const jsonLdData = computed<WithContext<BreadcrumbList>>(() => ({
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		itemListElement:
			crumbs.value?.map((c, index) => ({
				"@type": "ListItem",
				position: index + 1,
				item: {
					"@id": c.url || "",
					name: c.label,
				},
			})) || [],
	}));

	useJsonld(() => jsonLdData.value);
</script>
