<template>
	<form class="u-login-and-registration__tab-content" @submit.prevent="submitLogin" novalidate="true">
		<span class="text-color-primary font-set-4-bold d-block">Willkommen zurück</span>
		<span class="font-set-8 d-block">Bitte melden Sie sich an</span>

		<p class="u-form__error-message" v-if="formErrorMessage" role="alert" aria-atomic="true">{{ formErrorMessage }}</p>

		<AtomsInputText
			name="loginUsername"
			label="E-Mail-Adresse"
			v-model="formMail.fieldValue"
			:error-message="formMail.errorMessage"
			class="mt-7"
			:isValidated="formMail.isValidated"
			@change="formMail.validate"
			:required="true"
		></AtomsInputText>
		<AtomsInputText
			name="loginPassword"
			label="Passwort"
			v-model="formPassword.fieldValue"
			:error-message="formPassword.errorMessage"
			:inputType="'password'"
			class="mt-3"
			:isValidated="formPassword.isValidated"
			@change="formPassword.validate"
			:required="true"
		>
			<template v-slot:label="slotProps">
				<div>
					<label :for="slotProps.forName">{{ slotProps.label }}</label>
					<AtomsBaseCTA version="link" class="text-color-primary" @click="$emit('goto-passwordreset')" type="button">
						Vergessen?
					</AtomsBaseCTA>
				</div>
			</template>
		</AtomsInputText>
		<AtomsToggleSwitch name="loginPreserve" v-model="preserve" class="u-login__agreement-wrapper mt-7">
			Angemeldet bleiben
		</AtomsToggleSwitch>

		<AtomsBaseCTA class="u-login-and-registration__submit-button mt-7" version="primary" type="submit">Anmelden</AtomsBaseCTA>
	</form>
</template>

<script lang="ts" setup>
	import Validate from "../server/utils/form-validator";
	import { ref } from "vue";
	import { useRouteQuery } from "@vueuse/router";
	import { DwhTracking } from "~/tracking/track.dwh";

	const props = withDefaults(
		defineProps<{
			mail?: string;
			state?: "login" | "passwordreset";
		}>(),
		{
			state: "login",
		},
	);
	const returnurl = useRouteQuery("returnurl")?.value;

	// FORM CONTROLS
	const formMail = useFormValue({
		default: props.mail,
		validate: async (field) => {
			return await Validate.email(field, "Bitte geben Sie Ihre E-Mail-Adresse ein");
		},
	});
	const formPassword = useFormValue({
		validate: (field) => {
			return Validate.required(field, "Bitte geben Sie ein Passwort ein");
		},
	});

	const preserve = ref(true);
	const formErrorMessage = ref("");

	async function isValid() {
		const allValidations = await Promise.all([formMail.validate(), formPassword.validate()]);
		return allValidations.every((result) => result);
	}

	function followTemporarilyPersitedTopic() {
		const clientStore = useClientStore();
		const topicForLogin = clientStore.rememberTopicForLogin;
		if (topicForLogin) {
			useSetTopicFollowState(topicForLogin.topicId, [topicForLogin], "add");
			clientStore.rememberTopicForLogin = null;
		}
	}

	async function submitLogin() {
		if (!(await isValid())) return;

		formErrorMessage.value = "";

		const response = await fetch("/api/user/login", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				username: formMail.fieldValue,
				password: formPassword.fieldValue,
				publication: usePublication(),
				keepLogin: preserve.value,
			}),
		}).then((r) => {
			if (r.ok) {
				return r.json();
			} else if (r.status === 400) {
				formErrorMessage.value = `Die übermittelten Zugangsdaten sind ungültig.`;
			} else {
				formErrorMessage.value = formErrorMessage.value = r.statusText;
			}
		});

		if (response && response.ok) {
			try {
				const cookieData = useUserDataFromCookie();
				const tracker = new DwhTracking(usePublication());
				tracker.userLoggedIn({ userId_: cookieData.plenigoId });
			} catch (e) {}
			if (useIsApp() && typeof ApplicationEvents !== "undefined") {
				const iappsRegistration = new ApplicationEvents({});
				iappsRegistration.sendEvent("login", response.session);
			}
			followTemporarilyPersitedTopic();
			if (returnurl) {
				location.href = typeof returnurl === "string" ? returnurl : "";
			} else {
				location.reload();
			}
		}
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/_mixins.scss";

	/**
     * LOGIN
     */

	.u-login-and-registration__tab-content {
		padding: 2rem;

		@include tablet-above {
			padding: 4.5rem;
		}
	}
	.u-login-and-registration__submit-button {
		font-size: 1.125rem;
		font-weight: bold;
		padding: 1rem 1.5rem;
		line-height: 1;
		width: 100%;
	}

	.u-login-and-registration__secondary-button {
		margin: auto;
		display: block;
		margin-top: 0.625rem;
	}

	.u-login__agreement-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: var(--space-4);
	}

	.u-login-and-registration__link-icon {
		width: 1.25rem;
	}
</style>
