<template>
	<form class="u-login-and-registration__tab-content" v-if="currentState === 'registration'" @submit.prevent="submit" novalidate="true">
		<span class="text-color-primary font-set-4-bold d-block">Noch nicht registriert?</span>
		<span class="font-set-8 d-block">Jetzt Benutzerkonto anlegen</span>

		<p class="u-form__error-message" v-if="formErrorMessage" role="alert" aria-atomic="true">{{ formErrorMessage }}</p>

		<AtomsSelect
			class="mt-7"
			label="Anrede"
			name="salution"
			:options="['Frau', 'Herr']"
			v-model="formSalutation.fieldValue"
			@change="formSalutation.validate"
			:error-message="formSalutation.errorMessage"
			:isValidated="formSalutation.isValidated"
			emptyOptionLabel="Bitte wählen"
			:required="true"
		>
		</AtomsSelect>

		<AtomsInputText
			name="firstname"
			label="Vorname"
			ref="firstnameInput"
			v-model="formFirstName.fieldValue"
			@change="formFirstName.validate"
			:error-message="formFirstName.errorMessage"
			:isValidated="formFirstName.isValidated"
			class="mt-3"
			:required="true"
		></AtomsInputText>

		<AtomsInputText
			name="lastname"
			label="Nachname"
			v-model="formLastName.fieldValue"
			@change="formLastName.validate"
			:error-message="formLastName.errorMessage"
			:isValidated="formLastName.isValidated"
			class="mt-3"
			:required="true"
		></AtomsInputText>

		<AtomsInputText
			name="mail"
			label="E-Mail-Adresse"
			v-model="formMail.fieldValue"
			@change="formMail.validate"
			:error-message="formMail.errorMessage"
			:isValidated="formMail.isValidated"
			class="mt-3"
			:required="true"
		></AtomsInputText>

		<AtomsInputText
			name="password"
			label="Passwort"
			v-model="formPassword.fieldValue"
			@change="formPassword.validate"
			:error-message="formPassword.errorMessage"
			:isValidated="formPassword.isValidated"
			:inputType="'password'"
			class="mt-3"
			:required="true"
		>
			<template v-slot:label="slotProps">
				<label :for="slotProps.forName">
					{{ slotProps.label }}
					<PasswordFieldValidationInfo v-bind="passwordValidationInfo"></PasswordFieldValidationInfo>
				</label>
			</template>
		</AtomsInputText>
		<AtomsInputText
			name="repeatPassword"
			label="Passwort wiederholen"
			v-model="formRepeatPassword.fieldValue"
			@change="formRepeatPassword.validate"
			:error-message="formRepeatPassword.errorMessage"
			:isValidated="formRepeatPassword.isValidated"
			:inputType="'password'"
			class="mt-3"
			:required="true"
		></AtomsInputText>

		<AtomsToggleSwitch
			name="privacy"
			v-model="formPrivacy.fieldValue"
			@change="formPrivacy.validate"
			:required="true"
			:error="!!formPrivacy.errorMessage"
			:isValidated="formPrivacy.isValidated"
			class="mt-7"
		>
			Ich nehme die <NuxtLink to="/agb" target="_blank">AGB</NuxtLink> und die
			<NuxtLink to="/privacy" target="_blank">Datenschutzbestimmungen</NuxtLink> zur Kenntnis und stimme der Verwendung meiner Daten
			zu.
		</AtomsToggleSwitch>

		<AtomsToggleSwitch name="newsletter" v-model="formNewsletter.fieldValue" class="mt-7">{{
			registrationNewsletterText
		}}</AtomsToggleSwitch>

		<AtomsBaseCTA class="mt-7 d-block" version="primary" type="submit" :big="true" :fullsize="true">Registrieren</AtomsBaseCTA>
		<p class="u-registration-form__newsletter-text-hint d-block my-3 font-set-1" v-if="registrationNewsletterTextHint">
			{{ registrationNewsletterTextHint }}
		</p>
	</form>
	<section class="u-login-and-registration__tab-content fs-4" v-else-if="currentState === 'registrationsuccess'">
		<span class="text-color-primary font-set-4-bold d-block">Fast geschafft</span>
		<span class="font-set-8 d-block">Bitte bestätigen Sie Ihre Registrierung über den Link in Ihrem E-Mail-Postfach</span>
		<img src="/static/images/password-reset-success.svg" class="mx-auto mt-7 d-block max-w-100" />
		<p class="mt-7 lh-lg">
			<b>Sie haben keinen Link erhalten?</b><br />
			Bitte schauen Sie in Ihrem Spam-Ordner nach. Wenn Sie auch hier die E-Mail nicht finden, können Sie sich den Link erneut
			zusenden lassen.
		</p>
		<AtomsBaseCTA
			class="mt-7 d-block"
			version="primary"
			type="button"
			@click="resendMail"
			:disabled="disabledResendButton"
			:big="true"
			:fullsize="true"
		>
			Link erneut anfordern
		</AtomsBaseCTA>
		<strong v-if="showResendInfo" class="d-block font-set-2-bold mt-2 mx-auto text-color-primary text-center">
			Die Bestätigungsmail wurde erneut zugestellt
		</strong>
	</section>
</template>

<script lang="ts" setup>
	import Validate from "../server/utils/form-validator";
	import { ref, computed } from "vue";
	import { useFetch } from "@vueuse/core";

	export interface IRegistrationFormProps {
		inputDefaultMail?: string;
		inputDefaultFirstname?: string;
		inputDefaultLastname?: string;
		inputDefaultSalutation?: string;
		inputDefaultPrivacy?: boolean;
		inputDefaultNewsletter?: boolean;
		agbUrl: string;
		privacyUrl: string;
		registrationNewsletterText: string;
		registrationNewsletterTextHint?: string;
		state?: "registration" | "registrationsuccess" | "registrationuserexists";
	}

	const props = withDefaults(defineProps<IRegistrationFormProps>(), {
		state: "registration",
	});

	const currentState = ref(props.state);
	const passwordValidationInfo = reactive({});

	// FORM CONTROLS

	const formSalutation = useFormValue({
		default: props.inputDefaultSalutation,
		validate: (field) => {
			return Validate.required(field, "Bitte wählen Sie eine Anrede aus");
		},
	});

	const formFirstName = useFormValue({
		default: props.inputDefaultFirstname,
		validate: async (field) => {
			return await Validate.name(field, "Bitte geben Sie Ihren Vornamen ein");
		},
	});
	const formLastName = useFormValue({
		default: props.inputDefaultLastname,
		validate: async (field) => {
			return await Validate.name(field, "Bitte geben Sie Ihren Nachnamen ein");
		},
	});

	const formMail = useFormValue({
		default: props.inputDefaultMail,
		validate: async (field) => {
			return await Validate.email(field, "Bitte geben Sie Ihre E-Mail-Adresse ein");
		},
	});

	const formPassword = useFormValue({
		validate: async (field) => {
			return await Validate.password(field, "Bitte geben Sie ein gültiges Passwort ein", passwordValidationInfo);
		},
	});

	const formRepeatPassword = useFormValue({
		validate: (field) => {
			return Validate.required(field, "Bitte geben Sie ein gültiges Passwort ein") ||
				Validate.sameAs(formPassword.fieldValue, field, "Die Passwörter stimmen nicht überein.");
		},
	});

	const formPrivacy = useFormValue({
		default: props.inputDefaultPrivacy,
		validate: (field) => {
			return Validate.required(field);
		},
	});

	const formNewsletter = useFormValue({
		default: props.inputDefaultNewsletter,
	});

	const disabledResendButton = ref(false);
	const showResendInfo = ref(false);
	const registrationToken = ref("");

	const formErrorMessage = ref("");

	async function isValid() {
		const allValidations = await Promise.all([
			formSalutation.validate(),
			formMail.validate(),
			formPassword.validate(),
			formRepeatPassword.validate(),
			formFirstName.validate(),
			formLastName.validate(),
			formPrivacy.validate(),
		]);
		return await allValidations.every((result) => result);
	}

	async function submit() {
		if (!(await isValid())) return;

		formErrorMessage.value = "";

		try {
			await fetch("/api/user/registration", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					username: formMail.fieldValue,
					password: formPassword.fieldValue,
					salutation: formSalutation.fieldValue,
					firstname: formFirstName.fieldValue,
					lastname: formLastName.fieldValue,
					optin_privacy: formPrivacy.fieldValue,
					optin_newsletter: formNewsletter.fieldValue,
					publication: usePublication(),
					returnurl: document.location.href ?? ""
				}),
			}).then(async (response) => {
				if (response.status === 200) {
					const data = await response.json();
					if (useIsApp() && typeof ApplicationEvents !== "undefined") {
						const iappsRegistration = new ApplicationEvents({});
						iappsRegistration.sendEvent("registration", data);
					}
					currentState.value = "registrationsuccess";
					registrationToken.value = data.token;
				} else if (response.status === 402) {
					currentState.value = "registrationsuccess";
					registrationToken.value = "";
				} else if (response.statusMessage) {
					formErrorMessage.value = response.statusMessage;
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	async function resendMail() {
		disabledResendButton.value = true;

		if (registrationToken.value) {
			const response = await useFetch("/api/user/registration/resend", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify({
					token: registrationToken.value,
					publication: usePublication(),
				}),
			});

			const data = response?.data?.value ? JSON.parse(response?.data?.value) : {};

			if (data.statusCode) {
				showResendInfo.value = true;
			} else {
				showResendInfo.value = false;
				disabledResendButton.value = false;
			}
		}
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/_mixins.scss";

	/**
     * LOGIN
     */

	.u-login-and-registration__tab-content {
		padding: 2rem;

		@include tablet-above {
			padding: 4.5rem;
		}
	}

	.u-login-and-registration__secondary-button {
		margin: auto;
		display: block;
		margin-top: 0.625rem;
	}

	.u-login-and-registration__link-icon {
		width: 1.25rem;
	}

	.u-registration-form__newsletter-text-hint {
		white-space: pre-wrap;
	}
</style>
